<template>
  <div class="app-main">
    <div class="xq_banner"><img src="@/assets/img/banner5.png"></div>
    <div class="app-container">
      <div class="position">
        <h2>养生知识</h2>
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>养生知识</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="ind_xw_dt">
        <div class="xw_dt">
          <ul>
            <li class="xw_dt1" ><a>
              <div class="xw_dt1_left">
                <h4>女性三个地方“毛”越浓密越健康？</h4>
                <p>身上长体毛是非常正常的一件事情，男性身上的体毛大多数都特别的浓密，但是如果女人身上...</p>
                <p class="xw_dt1_left_clolr">MORE+</p>
              </div>
              <div class="xw_dt1_right">
                <p class="xw_dt1_right_sj">19</p>
                <p>2019-08</p>
              </div>
              </a> </li><li class="xw_dt1" ><a>
              <div class="xw_dt1_left">
                <h4>每天锻炼1小时，健康生活1辈子</h4>
                <p>为了满足广大人民群众日益增长的体育需求，为了纪念北京奥运会成功举办，从2009年起， 每年...</p>
                <p class="xw_dt1_left_clolr">MORE+</p>
              </div>
              <div class="xw_dt1_right">
                <p class="xw_dt1_right_sj">19</p>
                <p>2019-08</p>
              </div>
              </a> </li><li class="xw_dt1"   ><a>
              <div class="xw_dt1_left">
                <h4>快来瞧一瞧看一看，大暑有什么风俗习俗？</h4>
                <p>大暑来临，一年中最热的时候，这个时节里，我国民间有哪些习俗呢？ 一、晒伏姜 整个三伏天...</p>
                <p class="xw_dt1_left_clolr">MORE+</p>
              </div>
              <div class="xw_dt1_right">
                <p class="xw_dt1_right_sj">19</p>
                <p>2019-08</p>
              </div>
              </a> </li><li class="xw_dt1"   ><a>
              <div class="xw_dt1_left">
                <h4>衣物上膏药的清洗妙招</h4>
                <p>有时衣服蹭上了膏药可以用酒精加几滴水（或用高粱酒亦可），放在沾有膏药渍的地方搓揉，...</p>
                <p class="xw_dt1_left_clolr">MORE+</p>
              </div>
              <div class="xw_dt1_right">
                <p class="xw_dt1_right_sj">13</p>
                <p>2018-04</p>
              </div>
              </a> </li><li class="xw_dt1"   ><a>
              <div class="xw_dt1_left">
                <h4>您了解膏药治病的全过程吗？</h4>
                <p>膏药，是中药五大剂型丸、散、膏、丹、汤之一，神秘古老、渊源久远。早在《山海经》中就...</p>
                <p class="xw_dt1_left_clolr">MORE+</p>
              </div>
              <div class="xw_dt1_right">
                <p class="xw_dt1_right_sj">13</p>
                <p>2018-04</p>
              </div>
              </a> </li> 
            <!--list.var4-->
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
    };
  },
  components: {
    // CategoryNav,
  },
  methods: {
  },
  watch: {
    
  },
};
</script>

<style lang="less" scoped>
</style>